import { Injectable } from '@angular/core';
import { DocumentType, Office, Month } from '../models/certificados-model';
import { municipios } from './Municipios';

@Injectable({
  providedIn: 'root'
})
export class InicializerService {

  constructor() { }

  //INICIALIZADOR DE LOS TIPOS DE DOCUMENTO.
  inicializeDocumentTypes() {
    let documentTypes: DocumentType[] = [];
    let documentType: DocumentType;
    documentType = {
      idDT: 1,
      nameDT: "CÉDULA DE CIUDADANÍA",
      abbreviationDT: "CC",
      value: "CedulaDeCiudadania"
    }
    documentTypes.push(documentType);
    documentType = {
      idDT: 2,
      nameDT: "TARJETA DE IDENTIDAD",
      abbreviationDT: "TI",
      value: "TarjetaDeIdentidad"
    }
    documentTypes.push(documentType);
    documentType = {
      idDT: 3,
      nameDT: "PASAPORTE",
      abbreviationDT: "PA",
      value: "Pasaporte"
    }
    documentTypes.push(documentType);
    documentType = {
      idDT: 4,
      nameDT: "NIT",
      abbreviationDT: "NIT",
      value: "NIT"
    }
    documentTypes.push(documentType);
    //Devolvemos el listado con los diferentes tipos de documentos.
    return documentTypes;
  }

  //INICIALIZADOR DE LAS OFICINAS.
  inicializeOffices() {
    let offices: Office[] = municipios;
    return offices;
  }

  allMonths() {
    let months: Month[] = [
      {value: '01', name: 'Enero'},
      {value: '02', name: 'Febrero'},
      {value: '03', name: 'Marzo'},
      {value: '04', name: 'Abril'},
      {value: '05', name: 'Mayo'},
      {value: '06', name: 'Junio'},
      {value: '07', name: 'Julio'},
      {value: '08', name: 'Agosto'},
      {value: '09', name: 'Septiembre'},
      {value: '10', name: 'Octubre'},
      {value: '11', name: 'Noviembre'},
      {value: '12', name: 'Diciembre'}
    ]
    return months;
  }
}
