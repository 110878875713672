export const municipios = [
  { idOff: 1, itemValue: "2", valueOff: "002", nameOff: "ABEJORRAL",},
  { idOff: 2, itemValue: "109", valueOff: "232", nameOff: "ACACIAS" },
  { idOff: 3, itemValue: "77", valueOff: "150", nameOff: "AGUA DE DIOS" },
  { idOff: 4, itemValue: "95", valueOff: "196", nameOff: "AGUACHICA" },
  { idOff: 5, itemValue: "54", valueOff: "102", nameOff: "AGUADAS" },
  { idOff: 6, itemValue: "3", valueOff: "003", nameOff: "AMALFI" },
  { idOff: 7, itemValue: "153", valueOff: "351", nameOff: "AMBALEMA" },
  { idOff: 8, itemValue: "4", valueOff: "004", nameOff: "ANDES" },
  { idOff: 9, itemValue: "55", valueOff: "103", nameOff: "ANSERMA" },
  { idOff: 10, itemValue: "194", valueOff: "008", nameOff: "APARTADO" },
  { idOff: 11, itemValue: "130", valueOff: "292", nameOff: "APIA" },
  { idOff: 12, itemValue: "172", valueOff: "410", nameOff: "ARAUCA" },
  { idOff: 13, itemValue: "126", valueOff: "280", nameOff: "ARMENIA" },
  { idOff: 14, itemValue: "154", valueOff: "352", nameOff: "ARMERO" },
  { idOff: 15, itemValue: "113", valueOff: "242", nameOff: "BARBACOAS" },
  { idOff: 16, itemValue: "136", valueOff: "302", nameOff: "BARICHARA" },
  { idOff: 17, itemValue: "137", valueOff: "303", nameOff: "BARRANCABERMEJA" },
  { idOff: 18, itemValue: "30", valueOff: "040", nameOff: "BARRANQUILLA" },
  { idOff: 19, itemValue: "131", valueOff: "293", nameOff: "BELEN DE UMBRIA" },
  { idOff: 20, itemValue: "184", valueOff: "50C", nameOff: "BOGOTA ZONA CENTRO" },
  { idOff: 21, itemValue: "185", valueOff: "50N", nameOff: "BOGOTA ZONA NORTE" },
  { idOff: 22, itemValue: "190", valueOff: "50S", nameOff: "BOGOTA ZONA SUR" },
  { idOff: 23, itemValue: "64", valueOff: "122", nameOff: "BOLIVAR (CAUCA)" },
  { idOff: 24, itemValue: "135", valueOff: "300", nameOff: "BUCARAMANGA" },
  { idOff: 25, itemValue: "164", valueOff: "372", nameOff: "BUENAVENTURA" },
  { idOff: 26, itemValue: "165", valueOff: "373", nameOff: "BUGA" },
  { idOff: 27, itemValue: "187", valueOff: "261", nameOff: "CACHIRA" },
  { idOff: 28, itemValue: "155", valueOff: "354", nameOff: "CAJAMARCA" },
  { idOff: 29, itemValue: "127", valueOff: "282", nameOff: "CALARCA" },
  { idOff: 30, itemValue: "163", valueOff: "370", nameOff: "CALI" },
  { idOff: 31, itemValue: "65", valueOff: "124", nameOff: "CALOTO" },
  { idOff: 32, itemValue: "78", valueOff: "152", nameOff: "CAQUEZA" },
  { idOff: 33, itemValue: "32", valueOff: "060", nameOff: "CARTAGENA" },
  { idOff: 34, itemValue: "166", valueOff: "375", nameOff: "CARTAGO" },
  { idOff: 35, itemValue: "12", valueOff: "015", nameOff: "CAUCASIA" },
  { idOff: 36, itemValue: "73", valueOff: "143", nameOff: "CERETE" },
  { idOff: 37, itemValue: "156", valueOff: "355", nameOff: "CHAPARRAL" },
  { idOff: 38, itemValue: "138", valueOff: "306", nameOff: "CHARALA" },
  { idOff: 39, itemValue: "94", valueOff: "192", nameOff: "CHIMICHAGUA" },
  { idOff: 40, itemValue: "121", valueOff: "264", nameOff: "CHINACOTA" },
  { idOff: 41, itemValue: "74", valueOff: "144", nameOff: "CHINU" },
  { idOff: 42, itemValue: "38", valueOff: "072", nameOff: "CHIQUINQUIRA" },
  { idOff: 43, itemValue: "79", valueOff: "154", nameOff: "CHOCONTA" },
  { idOff: 44, itemValue: "103", valueOff: "222", nameOff: "CIENAGA" },
  { idOff: 45, itemValue: "5", valueOff: "005", nameOff: "CIUDAD BOLIVAR (ANT)" },
  { idOff: 46, itemValue: "193", valueOff: "308", nameOff: "CONCEPCION" },
  { idOff: 47, itemValue: "83", valueOff: "161", nameOff: "CONTRATACION" },
  { idOff: 48, itemValue: "122", valueOff: "266", nameOff: "CONVENCION" },
  { idOff: 49, itemValue: "149", valueOff: "342", nameOff: "COROZAL" },
  { idOff: 50, itemValue: "120", valueOff: "260", nameOff: "CUCUTA" },
  { idOff: 51, itemValue: "7", valueOff: "007", nameOff: "DABEIBA" },
  { idOff: 52, itemValue: "132", valueOff: "294", nameOff: "DOSQUEBRADAS" },
  { idOff: 53, itemValue: "39", valueOff: "074", nameOff: "DUITAMA" },
  { idOff: 54, itemValue: "104", valueOff: "224", nameOff: "EL BANCO" },
  { idOff: 55, itemValue: "33", valueOff: "062", nameOff: "EL CARMEN" },
  { idOff: 56, itemValue: "40", valueOff: "076", nameOff: "EL COCUY" },
  { idOff: 57, itemValue: "157", valueOff: "357", nameOff: "ESPINAL" },
  { idOff: 58, itemValue: "80", valueOff: "156", nameOff: "FACATATIVA" },
  { idOff: 59, itemValue: "128", valueOff: "284", nameOff: "FILANDIA" },
  { idOff: 60, itemValue: "173", valueOff: "420", nameOff: "FLORENCIA" },
  { idOff: 61, itemValue: "195", valueOff: "010", nameOff: "FREDONIA" },
  { idOff: 62, itemValue: "158", valueOff: "359", nameOff: "FRESNO" },
  { idOff: 63, itemValue: "8", valueOff: "011", nameOff: "FRONTINO" },
  { idOff: 64, itemValue: "105", valueOff: "225", nameOff: "FUNDACION" },
  { idOff: 65, itemValue: "81", valueOff: "157", nameOff: "FUSAGASUGA" },
  { idOff: 66, itemValue: "82", valueOff: "160", nameOff: "GACHETA" },
  { idOff: 67, itemValue: "41", valueOff: "078", nameOff: "GARAGOA" },
  { idOff: 68, itemValue: "97", valueOff: "202", nameOff: "GARZON" },
  { idOff: 69, itemValue: "139", valueOff: "307", nameOff: "GIRARDOT" },
  { idOff: 70, itemValue: "9", valueOff: "012", nameOff: "GIRARDOTA" },
  { idOff: 71, itemValue: "84", valueOff: "162", nameOff: "GUADUAS" },
  { idOff: 72, itemValue: "159", valueOff: "360", nameOff: "GUAMO" },
  { idOff: 73, itemValue: "66", valueOff: "126", nameOff: "GUAPI" },
  { idOff: 74, itemValue: "42",
    valueOff: "079",
    nameOff: "GUATEQUE",
  },
  { idOff: 75, itemValue: "160",
    valueOff: "362",
    nameOff: "HONDA",
  },
  { idOff: 76, itemValue: "152",
    valueOff: "350",
    nameOff: "IBAGUE",
  },
  { idOff: 77, itemValue: "192",
    valueOff: "500",
    nameOff: "INIRIDA",
  },
  { idOff: 78, itemValue: "114",
    valueOff: "244",
    nameOff: "IPIALES",
  },
  { idOff: 79, itemValue: "91",
    valueOff: "184",
    nameOff: "ISTMINA",
  },
  { idOff: 80, itemValue: "10",
    valueOff: "013",
    nameOff: "ITUANGO",
  },
  { idOff: 81, itemValue: "11",
    valueOff: "014",
    nameOff: "JERICO",
  },
  { idOff: 82, itemValue: "13",
    valueOff: "017",
    nameOff: "LA CEJA",
  },
  { idOff: 83, itemValue: "115",
    valueOff: "246",
    nameOff: "LA CRUZ",
  },
  { idOff: 84, itemValue: "56",
    valueOff: "106",
    nameOff: "LA DORADA",
  },
  { idOff: 85, itemValue: "85",
    valueOff: "166",
    nameOff: "LA MESA",
  },
  { idOff: 86, itemValue: "86",
    valueOff: "167",
    nameOff: "LA PALMA",
  },
  { idOff: 87, itemValue: "98",
    valueOff: "204",
    nameOff: "LA PLATA",
  },
  { idOff: 88, itemValue: "116",
    valueOff: "248",
    nameOff: "LA UNION",
  },
  { idOff: 89, itemValue: "171",
    valueOff: "400",
    nameOff: "LETICIA",
  },
  { idOff: 90, itemValue: "161",
    valueOff: "364",
    nameOff: "LIBANO",
  },
  {
    idOff: 91,
    itemValue: "75",
    valueOff: "146",
    nameOff: "LORICA",
  },
  {
    idOff: 92,
    itemValue: "34",
    valueOff: "064",
    nameOff: "MAGANGUE",
  },
  {
    idOff: 93,
    itemValue: "101",
    valueOff: "212",
    nameOff: "MAICAO",
  },
  {
    idOff: 94,
    itemValue: "140",
    valueOff: "312",
    nameOff: "MALAGA",
  },
  {
    idOff: 95,
    itemValue: "53",
    valueOff: "100",
    nameOff: "MANIZALES",
  },
  {
    idOff: 96,
    itemValue: "57",
    valueOff: "108",
    nameOff: "MANZANARES",
  },
  {
    idOff: 97,
    itemValue: "14",
    valueOff: "018",
    nameOff: "MARINILLA",
  },
  {
    idOff: 98,
    itemValue: "183",
    valueOff: "01N",
    nameOff: "MEDELLIN NORTE",
  },
  {
    idOff: 99,
    itemValue: "1",
    valueOff: "001",
    nameOff: "MEDELLIN SUR",
  },
  {
    idOff: 100,
    itemValue: "186",
    valueOff: "366",
    nameOff: "MELGAR",
  },
  {
    idOff: 101,
    itemValue: "44",
    valueOff: "082",
    nameOff: "MIRAFLORES",
  },
  {
    idOff: 102,
    itemValue: "188",
    valueOff: "520",
    nameOff: "MITÚ",
  },
  {
    idOff: 103,
    itemValue: "175",
    valueOff: "440",
    nameOff: "MOCOA",
  },
  {
    idOff: 104,
    itemValue: "35",
    valueOff: "065",
    nameOff: "MOMPOS",
  },
  {
    idOff: 105,
    itemValue: "45",
    valueOff: "083",
    nameOff: "MONIQUIRA",
  },
  {
    idOff: 106,
    itemValue: "72",
    valueOff: "142",
    nameOff: "MONTELIBANO",
  },
  {
    idOff: 107,
    itemValue: "71",
    valueOff: "140",
    nameOff: "MONTERIA",
  },
  {
    idOff: 108,
    itemValue: "58",
    valueOff: "110",
    nameOff: "NEIRA",
  },
  {
    idOff: 109,
    itemValue: "96",
    valueOff: "200",
    nameOff: "NEIVA",
  },
  {
    idOff: 110,
    itemValue: "92",
    valueOff: "186",
    nameOff: "NUQUI",
  },
  {
    idOff: 111,
    itemValue: "123",
    valueOff: "270",
    nameOff: "OCAÑA",
  },
  {
    idOff: 112,
    itemValue: "46",
    valueOff: "086",
    nameOff: "OROCUE",
  },
  {
    idOff: 113,
    itemValue: "87",
    valueOff: "170",
    nameOff: "PACHO",
  },
  {
    idOff: 114,
    itemValue: "59",
    valueOff: "112",
    nameOff: "PACORA",
  },
  {
    idOff: 115,
    itemValue: "167",
    valueOff: "378",
    nameOff: "PALMIRA",
  },
  {
    idOff: 116,
    itemValue: "124",
    valueOff: "272",
    nameOff: "PAMPLONA",
  },
  {
    idOff: 117,
    itemValue: "112",
    valueOff: "240",
    nameOff: "PASTO",
  },
  {
    idOff: 118,
    itemValue: "67",
    valueOff: "128",
    nameOff: "PATIA",
  },
  {
    idOff: 119,
    itemValue: "179",
    valueOff: "475",
    nameOff: "PAZ DE ARIPORO",
  },
  {
    idOff: 120,
    itemValue: "60",
    valueOff: "114",
    nameOff: "PENSILVANIA",
  },
  {
    idOff: 121,
    itemValue: "129",
    valueOff: "290",
    nameOff: "PEREIRA",
  },
  {
    idOff: 122,
    itemValue: "141",
    valueOff: "314",
    nameOff: "PIEDECUESTA",
  },
  {
    idOff: 123,
    itemValue: "99",
    valueOff: "206",
    nameOff: "PITALITO",
  },
  {
    idOff: 124,
    itemValue: "106",
    valueOff: "226",
    nameOff: "PLATO",
  },
  {
    idOff: 125,
    itemValue: "63",
    valueOff: "120",
    nameOff: "POPAYAN",
  },
  {
    idOff: 126,
    itemValue: "142",
    valueOff: "315",
    nameOff: "PUENTE NACIONAL",
  },
  {
    idOff: 127,
    itemValue: "176",
    valueOff: "442",
    nameOff: "PUERTO ASIS",
  },
  {
    idOff: 128,
    itemValue: "15",
    valueOff: "019",
    nameOff: "PUERTO BERRIO",
  },
  {
    idOff: 129,
    itemValue: "47",
    valueOff: "088",
    nameOff: "PUERTO BOYACA",
  },
  {
    idOff: 130,
    itemValue: "181",
    valueOff: "540",
    nameOff: "PUERTO CARREÑO",
  },
  {
    idOff: 131,
    itemValue: "110",
    valueOff: "234",
    nameOff: "PUERTO LOPEZ",
  },
  {
    idOff: 132,
    itemValue: "68",
    valueOff: "130",
    nameOff: "PUERTO TEJADA",
  },
  {
    idOff: 133,
    itemValue: "162",
    valueOff: "368",
    nameOff: "PURIFICACION",
  },
  {
    idOff: 134,
    itemValue: "90",
    valueOff: "180",
    nameOff: "QUIBDO",
  },
  {
    idOff: 135,
    itemValue: "48",
    valueOff: "090",
    nameOff: "RAMIRIQUI",
  },
  {
    idOff: 136,
    itemValue: "100",
    valueOff: "210",
    nameOff: "RIOHACHA",
  },
  {
    idOff: 137,
    itemValue: "16",
    valueOff: "020",
    nameOff: "RIONEGRO",
  },
  {
    idOff: 138,
    itemValue: "61",
    valueOff: "115",
    nameOff: "RIOSUCIO",
  },
  {
    idOff: 139,
    itemValue: "168",
    valueOff: "380",
    nameOff: "ROLDANILLO",
  },
  {
    idOff: 140,
    itemValue: "31",
    valueOff: "045",
    nameOff: "SABANALARGA",
  },
  {
    idOff: 141,
    itemValue: "76",
    valueOff: "148",
    nameOff: "SAHAGUN",
  },
  {
    idOff: 142,
    itemValue: "62",
    valueOff: "118",
    nameOff: "SALAMINA",
  },
  {
    idOff: 143,
    itemValue: "125",
    valueOff: "276",
    nameOff: "SALAZAR",
  },
  {
    idOff: 144,
    itemValue: "117",
    valueOff: "250",
    nameOff: "SAMANIEGO",
  },
  {
    idOff: 145,
    itemValue: "177",
    valueOff: "450",
    nameOff: "SAN ANDRES (Isla)",
  },
  {
    idOff: 146,
    itemValue: "182",
    valueOff: "318",
    nameOff: "SAN ANDRES (STDR)",
  },
  {
    idOff: 147,
    itemValue: "143",
    valueOff: "319",
    nameOff: "SAN GIL",
  },
  {
    idOff: 148,
    itemValue: "180",
    valueOff: "480",
    nameOff: "SAN JOSE DEL GUAVIAR",
  },
  {
    idOff: 149,
    itemValue: "102",
    valueOff: "214",
    nameOff: "SAN JUAN DEL CESAR",
  },
  {
    idOff: 150,
    itemValue: "150",
    valueOff: "346",
    nameOff: "SAN MARCOS",
  },
  {
    idOff: 151,
    itemValue: "111",
    valueOff: "236",
    nameOff: "SAN MARTIN",
  },
  {
    idOff: 152,
    itemValue: "144",
    valueOff: "320",
    nameOff: "SAN VICENTE DE CHUCURI",
  },
  {
    idOff: 153,
    itemValue: "174",
    valueOff: "425",
    nameOff: "SAN VICENTE DEL CAGUAN",
  },
  {
    idOff: 154,
    itemValue: "17",
    valueOff: "023",
    nameOff: "SANTA BARBARA",
  },
  {
    idOff: 155,
    itemValue: "18",
    valueOff: "024",
    nameOff: "SANTA FE DE ANTIOQUIA",
  },
  {
    idOff: 156,
    itemValue: "43",
    valueOff: "080",
    nameOff: "SANTA MARTA",
  },
  {
    idOff: 157,
    itemValue: "133",
    valueOff: "296",
    nameOff: "SANTA ROSA DE CABAL",
  },
  {
    idOff: 158,
    itemValue: "19",
    valueOff: "025",
    nameOff: "SANTA ROSA DE OSOS",
  },
  {
    idOff: 159,
    itemValue: "49",
    valueOff: "092",
    nameOff: "SANTA ROSA DE VITERBO",
  },
  {
    idOff: 160,
    itemValue: "69",
    valueOff: "132",
    nameOff: "SANTANDER DE QUILICHAO",
  },
  {
    idOff: 161,
    itemValue: "20",
    valueOff: "026",
    nameOff: "SANTO DOMINGO",
  },
  {
    idOff: 162,
    itemValue: "134",
    valueOff: "297",
    nameOff: "SANTUARIO",
  },
  {
    idOff: 163,
    itemValue: "21",
    valueOff: "027",
    nameOff: "SEGOVIA",
  },
  {
    idOff: 164,
    itemValue: "169",
    valueOff: "382",
    nameOff: "SEVILLA",
  },
  {
    idOff: 165,
    itemValue: "196",
    valueOff: "441",
    nameOff: "SIBUNDOY",
  },
  {
    idOff: 166,
    itemValue: "70",
    valueOff: "134",
    nameOff: "SILVIA",
  },
  {
    idOff: 167,
    itemValue: "36",
    valueOff: "068",
    nameOff: "SIMITI",
  },
  {
    idOff: 168,
    itemValue: "151",
    valueOff: "347",
    nameOff: "SINCE",
  },
  {
    idOff: 169,
    itemValue: "148",
    valueOff: "340",
    nameOff: "SINCELEJO",
  },
  {
    idOff: 170,
    itemValue: "107",
    valueOff: "228",
    nameOff: "SITIONUEVO",
  },
  {
    idOff: 171,
    itemValue: "191",
    valueOff: "051",
    nameOff: "SOACHA",
  },
  {
    idOff: 172,
    itemValue: "50",
    valueOff: "093",
    nameOff: "SOATA",
  },
  {
    idOff: 173,
    itemValue: "51",
    valueOff: "094",
    nameOff: "SOCHA",
  },
  {
    idOff: 174,
    itemValue: "145",
    valueOff: "321",
    nameOff: "SOCORRO",
  },
  {
    idOff: 175,
    itemValue: "52",
    valueOff: "095",
    nameOff: "SOGAMOSO",
  },
  {
    idOff: 176,
    itemValue: "189",
    valueOff: "041",
    nameOff: "SOLEDAD",
  },
  {
    idOff: 177,
    itemValue: "22",
    valueOff: "028",
    nameOff: "SONSON",
  },
  {
    idOff: 178,
    itemValue: "23",
    valueOff: "029",
    nameOff: "SOPETRAN",
  },
  {
    idOff: 179,
    itemValue: "24",
    valueOff: "032",
    nameOff: "TAMESIS",
  },
  {
    idOff: 180,
    itemValue: "25",
    valueOff: "033",
    nameOff: "TITIRIBI",
  },
  {
    idOff: 181,
    itemValue: "170",
    valueOff: "384",
    nameOff: "TULUA",
  },
  {
    idOff: 182,
    itemValue: "118",
    valueOff: "252",
    nameOff: "TUMACO",
  },
  {
    idOff: 183,
    itemValue: "37",
    valueOff: "070",
    nameOff: "TUNJA",
  },
  {
    idOff: 184,
    itemValue: "119",
    valueOff: "254",
    nameOff: "TUQUERRES",
  },
  {
    idOff: 185,
    itemValue: "26",
    valueOff: "034",
    nameOff: "TURBO",
  },
  {
    idOff: 186,
    itemValue: "88",
    valueOff: "172",
    nameOff: "UBATE",
  },
  {
    idOff: 187,
    itemValue: "27",
    valueOff: "035",
    nameOff: "URRAO",
  },
  {
    idOff: 188,
    itemValue: "93",
    valueOff: "190",
    nameOff: "VALLEDUPAR",
  },
  {
    idOff: 189,
    itemValue: "146",
    valueOff: "324",
    nameOff: "VELEZ",
  },
  {
    idOff: 190,
    itemValue: "108",
    valueOff: "230",
    nameOff: "VILLAVICENCIO",
  },
  {
    idOff: 191,
    itemValue: "28",
    valueOff: "037",
    nameOff: "YARUMAL",
  },
  {
    idOff: 192,
    itemValue: "29",
    valueOff: "038",
    nameOff: "YOLOMBO",
  },
  {
    idOff: 193,
    itemValue: "178",
    valueOff: "470",
    nameOff: "YOPAL",
  },
  {
    idOff: 194,
    itemValue: "147",
    valueOff: "326",
    nameOff: "ZAPATOCA",
  },
  {
    idOff: 195,
    itemValue: "89",
    valueOff: "176",
    nameOff: "ZIPAQUIRA",
  },
];
